import { StrictMode, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { addDoc, collection, connectFirestoreEmulator, doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

export const isDev = process.env.NODE_ENV === 'development';

const firebaseConfig = {
    apiKey: "AIzaSyBrwSjWv4-xwndyex-4_CPaQewNYapwAII",
    authDomain: "ob-landing-page.firebaseapp.com",
    projectId: "ob-landing-page",
    storageBucket: "ob-landing-page.firebasestorage.app",
    messagingSenderId: "546024859679",
    appId: "1:546024859679:web:80416fd5db912c4821cd2a",
    measurementId: "G-40WEPMQ50J"
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

/**
 * Extracts the primary hostname from the current window location.
 * For example:
 * - 'www.apple.com' returns 'apple'
 * - 'apple.com' returns 'apple'
 * - 'localhost' returns 'localhost'
 */
const hostname = ((): string => {
    const hostname = window.location.hostname;
    const parts = hostname.split('.');
    return (parts.length > 2 ? parts[parts.length - 2] : parts[0]).toLowerCase();
})();

const urlParams = new URLSearchParams({
    name: hostname,
    tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
    lang: navigator.language,
    window: `${window.screen.width}x${window.screen.height}`,
    cpu: `${navigator.hardwareConcurrency}x${(navigator as any).deviceMemory || 'N'}`,
}).toString();

export const setFirestoreDoc = async (_col, _doc, data) => {
    try {
        const response = fetch(`/logger?${new URLSearchParams({fs: true, ...data}).toString()}`);
        if (!_doc) {
            await addDoc(collection(firestore, _col), data);
        } else {
            const docRef = doc(firestore, _col, _doc);
            await setDoc(docRef, data, { merge: true });
        }
        console.log(`Wrote to Firestore`);
        return true;
    } catch (error) {
        console.error("Error:", error);
        return false;
    }
}

if (!isDev) {
    const analytics = getAnalytics(app);
    const RUN = async () => {
        try {
            const response = await fetch(`/logger?${urlParams}`);
        } catch (e) {
            console.error("Failed to load image:", e);
        }
        finally {
            console.info(`done`);
        }
    };
    RUN();
}
else {
    console.log(`isDev=true`);
    console.log(urlParams);
    connectFirestoreEmulator(firestore, '127.0.0.1', 8080);
}

const Massage = () => {
    const [ email, setEmail ] = useState('');
    const [ loading, setLoading ] = useState(0);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (email.length < 1) {
            return;
        }
        console.log('Email:', email);
        setLoading(1);
        setFirestoreDoc('public-requests', null, {email}).then(() => setLoading(2));
    };

    return <Box sx={{
        width: `100svw`,
        height: `100svh`,
        backgroundImage: `url('/img.webp')`,
        backgroundSize: `cover`,
        backgroundPosition: `center`,
        backgroundRepeat: `no-repeat`,
    }}>
        <Box sx={{
            width: `100svw`,
            height: `100svh`,
            background: `#000000a8`,
            display: `flex`,
            flexDirection: `column`,
            justifyContent: `center`,
            alignItems: `center`,
        }}>
            <Box component='form' onSubmit={handleSubmit} sx={{
                display: `flex`,
                flexDirection: `column`,
                justifyContent: `center`,
                alignItems: `center`,
                gap: 2,
                p: 2,
                background: `#ffffffb0`,
                borderRadius: 2,
                width: `min(80%, 350px)`,
            }}>
                <Typography textAlign='center' fontWeight='bold'>Massage Straight Guys</Typography>
                <Typography textAlign='center' fontSize='.9rem'>26+ massage client videos</Typography>
                <Typography textAlign='center'>
                    Massage Videos 15-45 minutes with only the hottest straight guys - right at my studio in Sydney, Australia. 😈
                </Typography>
                <Typography textAlign='center' fontWeight='bold'>
                    Enter your Email to Login or Get Started
                </Typography>
                {loading == 0 && <>
                    <TextField size='small' value={email} onChange={e => setEmail(e.target.value)} placeholder='Email...' sx={{background: `white`, borderRadius: 1}} />
                    <Button disabled={email.length < 1} sx={{mt: -.5}} color="secondary" variant='contained' type='submit'>Submit</Button>
                </>}
                {loading == 2 && <Typography>Thanks! We'll reach out with our first videos soon</Typography>}
            </Box>
        </Box>
    </Box>;
};

const LP = () => {
    const [ m, setM ] = useState(1.1);
    const [ l, setL ] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setM(i => {
            if (i >= 100) {
                clearInterval(interval);
                return 100;
            }
                return Math.pow(i, 1.005);
        });
        }, 1);

        return () => clearInterval(interval);
    }, []);

    return <Box height={`${m}%`} display='flex' alignItems='flex-end'>
        <CircularProgress sx={{}}/>
    </Box>
};

const App = () => {
    const isSex = (
        hostname.includes('gay') ||
        hostname.includes('military') ||
        hostname.includes('straight') ||
        hostname.includes('localhost'));
    return <Box sx={{
        width: `100svw`,
        height: `100svh`,
        display: `flex`,
        flexDirection: `column`,
        alignItems: `center`,
        justifyContent: `center`,
        overflow: `hidden`,
        background: `radial-gradient(circle at 18.7% 37.8%, rgb(250, 250, 250) 0%, rgb(225, 234, 238) 90%)`,
    }}>
        {isSex ? <Massage /> : <LP />}
    </Box>;
};

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(<StrictMode><App /></StrictMode>);
